import React, { useState, useEffect } from "react";
import { IoSearch } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import CustomCheckbox from "./CustomCheckbox";
import JobCard from "./JobCard";
import ClipLoader from "react-spinners/ClipLoader";
import debounce from "lodash.debounce";
import SimpleDropdown from "./DropDown";
import { Slider } from "@mui/material";
import dayjs from "dayjs";
import { getAllJobsCall } from "../../apis/job-posting/repo";
import { SUCCESS } from "../../utils/constants";
import proposal_bg from "../../assets/subscribe_bg.png";
import { BsReverseLayoutSidebarReverse } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import { getJobType } from "../../apis/job-categories/repo";

const JobSearch = () => {
  const minSalary = 0;
  const maxSalary = 100000;
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search") || "";
  const [state, setState] = useState({
    listlength :'',
    jobsList: [],
    datePostedValue: 1,
    startDate: "",
    endDate: "",
  });
  const [searchValues, setSearchValues] = useState({
    keywordSearch: "",
    jobTitleSearch: "",
    locationSearch: "",
  });
  const [minSalaryValue, setMinSalaryValue] = useState(minSalary);
  const [maxSalaryValue, setMaxSalaryValue] = useState(maxSalary);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [salaryRange, setSalaryRange] = useState([minSalary, maxSalary]);
  const [experienceLevel, setExperienceLevel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [jobTypes, setJobTypes] = useState([]);
  const [totalPages ,setTotalPages ] = useState('')
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [jobsPerPage] = useState(3); // Number of jobs per page
  const debouncedGetJobs = debounce(() => {
    getJobs();
  }, 500);

  useEffect(() => {
    debouncedGetJobs();
  }, [state.datePostedValue, minSalaryValue, maxSalaryValue, currentPage]);

  useEffect(() => {
    jobType();
  }, []);

  function jobType() {
    getJobType().then(({ data }) => {
      if (data.error_code === SUCCESS) {
        setJobTypes(data.data);
      } else {
        console.log(data.message);
      }
    });
  }

  function getJobs() {
    setLoading(true);
    getAllJobsCall(
      searchValues.keywordSearch,
      searchValues.jobTitleSearch,
      searchValues.locationSearch,
      state.startDate
        ? dayjs(state.startDate).format("YYYY-MM-DD 00:00:00")
        : "",
      state.endDate ? dayjs(state.endDate).format("YYYY-MM-DD 23:59:59") : "",
      minSalaryValue,
      maxSalaryValue,
      selectedJobTypes,
      experienceLevel,
      currentPage,
      jobsPerPage
    )
      .then(({ data }) => {
        if (data.error_code === SUCCESS) {
          setTotalPages(data.totalPages)
          setState((prevState) => ({ ...prevState, jobsList: data.data }));
          setState((prevState) => ({ ...prevState, listlength: data.totalRecords
          }));
        } else {
          setState((prevState) => ({ ...prevState, jobsList: [] }));
        }
      })
      .catch((err) => {
        console.error("Error fetching jobs:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleApply = () => {
    setCurrentPage(1); // Reset to first page on apply
    debouncedGetJobs();
  };

  const handleRemoveAll = () => {
    setSearchValues({
      keywordSearch: "",
      jobTitleSearch: "",
      locationSearch: "",
    });
    setState({
      ...state,
      datePostedValue: 1,
      startDate: "",
      endDate: "",
    });
    setMinSalaryValue(minSalary);
    setMaxSalaryValue(maxSalary);
    setSelectedJobTypes([]);
    setSalaryRange([minSalary, maxSalary]);
    setExperienceLevel([]);
    setCurrentPage(1); // Reset to first page on remove all
    debouncedGetJobs();
  };

  const handleChange = (event, newValue) => {
    setSalaryRange(newValue);
    setMinSalaryValue(newValue[0]);
    setMaxSalaryValue(newValue[1]);
  };

  const valuetext = (value) => `${value}`;


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleJobType = (type) => {
    setSelectedJobTypes((prev) =>
      prev.includes(type)
        ? prev.filter((item) => item !== type)
        : [...prev, type]
    );
  };

  const toggleExperienceLevel = (level) => {
    setExperienceLevel((prev) =>
      prev.includes(level)
        ? prev.filter((item) => item !== level)
        : [...prev, level]
    );
  };

  useEffect(() => {
    if (state.datePostedValue !== 1) {
      let startDate = "";
      let endDate = new Date();

      if (state.datePostedValue === 2) {
        startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000);
      } else if (state.datePostedValue === 3) {
        startDate = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      } else if (state.datePostedValue === 4) {
        startDate = new Date(endDate.getTime() - 14 * 24 * 60 * 60 * 1000);
      } else if (state.datePostedValue === 5) {
        startDate = new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000);
      }

      setState((prevState) => ({ ...prevState, startDate, endDate }));
    } else {
      setState((prevState) => ({ ...prevState, startDate: "", endDate: "" }));
    }
  }, [state.datePostedValue]);

  const handleDateChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      datePostedValue: value,
    }));
  };

  // Toggle filter visibility
  const toggleFilterVisibility = () => {
    setShowFilter((prev) => !prev);
  };

  useEffect(() => {
    setSearchValues((prev) => ({
      ...prev,
      jobTitleSearch: searchQuery,
    }));
    debouncedGetJobs();
  }, [searchQuery]);
  return (
    <div className="w-full text-white">
      <div className="relative -top-8 w-[95%] sm:w-[90%] lg:w-[80%] bg-[#B89D32] mx-auto flex flex-col sm:flex-row rounded-3xl sm:rounded-full py-2">
        <div className="w-full sm:w-1/2 sm:border-r flex items-center px-5 gap-5 py-1.5">
          <IoSearch className="text-2xl text-white" />
          <input
            placeholder="Search Job Title Here"
            type="text"
            value={searchValues.jobTitleSearch}
            onChange={(e) =>
              setSearchValues({
                ...searchValues,
                jobTitleSearch: e.target.value,
              })
            }
            className="text-sm sm:text-base w-full bg-transparent outline-none text-white placeholder:text-white"
          />
        </div>
        <div className="w-full sm:w-1/2 justify-between flex px-5 gap-5 py-1.5">
          <div className="flex items-center gap-5">
            <FaLocationDot className="text-xl text-white" />
            <input
              placeholder="Location"
              type="text"
              value={searchValues.locationSearch}
              onChange={(e) =>
                setSearchValues({
                  ...searchValues,
                  locationSearch: e.target.value,
                })
              }
              className="text-sm sm:text-base w-full bg-transparent outline-none text-white placeholder:text-white"
            />
          </div>
          <div>
            <button
              className="px-4 sm:px-8 py-2 bg-white rounded-full text-xs sm:text-sm text-black"
              onClick={handleApply}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div style={{ background: `url(${proposal_bg})` }}>
        <div className="w-[90%] md:w-[80%] mx-auto gap-5 flex py-14 relative">
          {/* Sidebar Button for Small Screens */}
          {!showFilter && (
            <button
              className="md:hidden bg-[#CEB551] text-white px-4 py-2 rounded-full absolute top-5 left-5"
              onClick={toggleFilterVisibility}
            >
              <BsReverseLayoutSidebarReverse />
            </button>
          )}

          {/* Filter Sidebar */}
          <div
            className={`bg-[#262626] md:bg-transparent text-white p-5 rounded-md md:w-[35%] absolute md:relative z-10 ${
              showFilter ? "block" : "hidden md:block"
            }`}
          >
            <div className="flex justify-end">
              <button
                onClick={toggleFilterVisibility}
                className="text-white text-xl md:hidden pb-5"
              >
                &times; {/* Close button */}
              </button>
            </div>

            {/* Rest of the filter options */}
            <div className="flex justify-between items-center gap-3">
              <h2 className="text-xl font-semibold">Filter</h2>
              <div className="flex justify-end gap-2 items-center">
                <button
                  onClick={handleApply}
                  className="bg-[#CEB551] text-white px-3 py-1 rounded-full text-xs"
                >
                  Apply
                </button>
                <button
                  onClick={handleRemoveAll}
                  className="bg-transparent border border-[#CEB551] text-[#CEB551] px-3 py-1 rounded-full text-xs"
                >
                  Remove All
                </button>
              </div>
            </div>

            {/* Job Type Filter */}
            <div className="mb-6">
              <h3 className="font-semibold mb-2 text-start font-xl py-3">
                Job Type
              </h3>
              <div className="space-y-2">
                {jobTypes?.map((type) => (
                  <label
                    key={type._id}
                    className="flex items-center space-x-2"
                  >
                    <CustomCheckbox
                      checked={selectedJobTypes.includes(type.title)}
                      onChange={() => toggleJobType(type.title)}
                      id={`job-type-${type._id}`}
                    />
                    <span>{type.title}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* Salary Range Filter */}
            <div className="mb-6">
              <h3 className="font-semibold mb-2 text-start font-xl py-3">
                Salary Range
              </h3>
              <Slider
                value={salaryRange}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaLabel={() => "Salary range"}
                getAriaValueText={valuetext}
                min={minSalary}
                max={maxSalary}
                sx={{
                  color: "#CEB551",
                  "& .MuiSlider-thumb": { backgroundColor: "#CEB551" },
                  "& .MuiSlider-track": { backgroundColor: "#CEB551" },
                  "& .MuiSlider-rail": {
                    backgroundColor: "#CEB551",
                    opacity: 0.5,
                  },
                }}
              />
            </div>

            {/* Experience Level Filter */}
            <div>
              <h3 className="font-semibold mb-2 text-start font-xl py-3">
                Experience Level
              </h3>
              <div className="space-y-2">
                {["Entry/Junior", "Mid Level", "Senior Level", "Executive"].map(
                  (level) => (
                    <label key={level} className="flex items-center space-x-2">
                      <CustomCheckbox
                        checked={experienceLevel.includes(level)}
                        onChange={() => toggleExperienceLevel(level)}
                        id={`experience-level-${level}`}
                      />
                      <span>{level}</span>
                    </label>
                  )
                )}
              </div>
            </div>
          </div>

          {/* Job Cards Section */}
          <div className="w-[90%] mx-auto md:w-[65%] flex flex-col gap-5">
            <div
              className="text-[#888888] text-start flex justify-between"
              style={{ fontWeight: "bolder" }}
            >
              Showing {state.listlength} Results
              <SimpleDropdown handleDateChange={handleDateChange} />
            </div>

            {loading ? (
              <div className="flex justify-center items-center w-full h-[200px]">
                <ClipLoader color="#CEB551" loading={loading} size={50} />
              </div>
            ) : state.jobsList.length > 0 ? (
              <>
                 <div className="flex-1">
          <div className="w-full flex flex-col gap-5">
            {state.jobsList.map((job, index) => (
              <JobCard key={index} {...job} />
            ))}
          </div>
          {loading && (
            <div className="flex justify-center items-center h-16">
              <ClipLoader color="#B89D32" />
            </div>
          )}
          
          </div>
              </>
            ) : (
              <p className="text-white text-center">No jobs found</p>
            )}
            <div className="flex justify-between items-center mt-5">
            <button
              className="px-4 py-2 featureGradientYellow rounded-full text-white"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <div>
              Page {currentPage} / {totalPages}
            </div>
            <button
              className="px-4 py-2 featureGradientYellow rounded-full text-white"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSearch;
