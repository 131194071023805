import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { HiMiniClock } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

function JobCard(job) {
  const navigate = useNavigate()
  return (
    <div className="w-full p-5 border border-[#979797] rounded-md featureGradientBlack text-start">
      <div className="w-full flex justify-between">
        <h1 className="text-[#CEB551] font-semibold text-xl pb-1">
          {job.job_title}
        </h1>
        <div className="text-[#CEB551] font-semibold text-end">{job.min_salary}$ - {job.max_salary}$</div>
      </div>
      <div className="gap-10 flex text-[12px] text-start">
        <div>{job.job_title}</div>
        <div className="flex items-center gap-2">
          {" "}
          <FaLocationDot className="text-sm text-white" /> {job.address}
        </div>
      </div>
      <div
      className="text-start text-xs py-5 truncate-lines-3 h-[4.3rem]"
      dangerouslySetInnerHTML={{ __html: job.job_description }}
    />
      <div className="flex flex-col sm:flex-row justify-between gap-1 sm:gap-0 items-center pt-2">
      <div className="flex flex-col sm:flex-row justify-start gap-1 sm:gap-5 pt-2">
        <button className="bg-white text-black px-3 py-1 rounded-full text-xs">
        {job.job_typeId.title}
        </button>
        <button className="bg-white text-black px-3 py-1 rounded-full text-xs flex items-center gap-2">
        <HiMiniClock  className="text-black"/>
        {job.experience}
        </button>
      </div>
      <div>
        <button 
        onClick={()=>  navigate("/apply-directly", { state: job._id })}
        className="featureGradientYellow te px-5 py-1 rounded-full text-sm flex items-center gap-2">Apply here</button>
      </div>
      </div>
    </div>
  );
}

export default JobCard;
